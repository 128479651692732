exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-contact-us-careers-js": () => import("./../../../src/pages/contact-us/careers.js" /* webpackChunkName: "component---src-pages-contact-us-careers-js" */),
  "component---src-pages-contact-us-get-in-touch-js": () => import("./../../../src/pages/contact-us/get-in-touch.js" /* webpackChunkName: "component---src-pages-contact-us-get-in-touch-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-enquiry-form-js": () => import("./../../../src/pages/enquiry-form.js" /* webpackChunkName: "component---src-pages-enquiry-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-our-services-business-financial-planning-contingency-planning-continuity-planning-js": () => import("./../../../src/pages/our-services/business-financial-planning/contingency-planning-continuity-planning.js" /* webpackChunkName: "component---src-pages-our-services-business-financial-planning-contingency-planning-continuity-planning-js" */),
  "component---src-pages-our-services-business-financial-planning-js": () => import("./../../../src/pages/our-services/business-financial-planning.js" /* webpackChunkName: "component---src-pages-our-services-business-financial-planning-js" */),
  "component---src-pages-our-services-citizenship-by-investment-js": () => import("./../../../src/pages/our-services/citizenship-by-investment.js" /* webpackChunkName: "component---src-pages-our-services-citizenship-by-investment-js" */),
  "component---src-pages-our-services-currency-exchange-js": () => import("./../../../src/pages/our-services/currency-exchange.js" /* webpackChunkName: "component---src-pages-our-services-currency-exchange-js" */),
  "component---src-pages-our-services-estate-planning-js": () => import("./../../../src/pages/our-services/estate-planning.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-js" */),
  "component---src-pages-our-services-estate-planning-trusts-and-gifting-js": () => import("./../../../src/pages/our-services/estate-planning/trusts-and-gifting.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-trusts-and-gifting-js" */),
  "component---src-pages-our-services-estate-planning-wills-js": () => import("./../../../src/pages/our-services/estate-planning/wills.js" /* webpackChunkName: "component---src-pages-our-services-estate-planning-wills-js" */),
  "component---src-pages-our-services-financial-advice-js": () => import("./../../../src/pages/our-services/financial-advice.js" /* webpackChunkName: "component---src-pages-our-services-financial-advice-js" */),
  "component---src-pages-our-services-insurance-critical-illness-js": () => import("./../../../src/pages/our-services/insurance/critical-illness.js" /* webpackChunkName: "component---src-pages-our-services-insurance-critical-illness-js" */),
  "component---src-pages-our-services-insurance-disability-insurance-js": () => import("./../../../src/pages/our-services/insurance/disability-insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-disability-insurance-js" */),
  "component---src-pages-our-services-insurance-income-protection-js": () => import("./../../../src/pages/our-services/insurance/income-protection.js" /* webpackChunkName: "component---src-pages-our-services-insurance-income-protection-js" */),
  "component---src-pages-our-services-insurance-js": () => import("./../../../src/pages/our-services/insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-js" */),
  "component---src-pages-our-services-insurance-life-insurance-js": () => import("./../../../src/pages/our-services/insurance/life-insurance.js" /* webpackChunkName: "component---src-pages-our-services-insurance-life-insurance-js" */),
  "component---src-pages-our-services-investments-asset-and-portfolio-management-js": () => import("./../../../src/pages/our-services/investments/asset-and-portfolio-management.js" /* webpackChunkName: "component---src-pages-our-services-investments-asset-and-portfolio-management-js" */),
  "component---src-pages-our-services-investments-education-planning-js": () => import("./../../../src/pages/our-services/investments/education-planning.js" /* webpackChunkName: "component---src-pages-our-services-investments-education-planning-js" */),
  "component---src-pages-our-services-investments-international-investments-js": () => import("./../../../src/pages/our-services/investments/international-investments.js" /* webpackChunkName: "component---src-pages-our-services-investments-international-investments-js" */),
  "component---src-pages-our-services-investments-js": () => import("./../../../src/pages/our-services/investments.js" /* webpackChunkName: "component---src-pages-our-services-investments-js" */),
  "component---src-pages-our-services-investments-local-investments-js": () => import("./../../../src/pages/our-services/investments/local-investments.js" /* webpackChunkName: "component---src-pages-our-services-investments-local-investments-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-services-property-investment-js": () => import("./../../../src/pages/our-services/property-investment.js" /* webpackChunkName: "component---src-pages-our-services-property-investment-js" */),
  "component---src-pages-our-services-retirement-planning-annuities-js": () => import("./../../../src/pages/our-services/retirement-planning/annuities.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-annuities-js" */),
  "component---src-pages-our-services-retirement-planning-js": () => import("./../../../src/pages/our-services/retirement-planning.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-js" */),
  "component---src-pages-our-services-retirement-planning-provident-pension-fund-js": () => import("./../../../src/pages/our-services/retirement-planning/provident-pension-fund.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-provident-pension-fund-js" */),
  "component---src-pages-our-services-retirement-planning-uk-pension-js": () => import("./../../../src/pages/our-services/retirement-planning/uk-pension.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-uk-pension-js" */),
  "component---src-pages-our-services-retirement-planning-uk-pension-transfer-js": () => import("./../../../src/pages/our-services/retirement-planning/uk-pension-transfer.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-uk-pension-transfer-js" */),
  "component---src-pages-our-services-retirement-planning-uk-state-pension-js": () => import("./../../../src/pages/our-services/retirement-planning/uk-state-pension.js" /* webpackChunkName: "component---src-pages-our-services-retirement-planning-uk-state-pension-js" */),
  "component---src-pages-our-services-tax-planning-js": () => import("./../../../src/pages/our-services/tax-planning.js" /* webpackChunkName: "component---src-pages-our-services-tax-planning-js" */),
  "component---src-pages-our-services-tax-planning-spanish-tax-planning-js": () => import("./../../../src/pages/our-services/tax-planning/spanish-tax-planning.js" /* webpackChunkName: "component---src-pages-our-services-tax-planning-spanish-tax-planning-js" */),
  "component---src-pages-our-services-tax-planning-wealth-tax-planning-js": () => import("./../../../src/pages/our-services/tax-planning/wealth-tax-planning.js" /* webpackChunkName: "component---src-pages-our-services-tax-planning-wealth-tax-planning-js" */),
  "component---src-pages-our-services-wealth-management-js": () => import("./../../../src/pages/our-services/wealth-management.js" /* webpackChunkName: "component---src-pages-our-services-wealth-management-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../../../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-who-we-are-about-us-js": () => import("./../../../src/pages/who-we-are/about-us.js" /* webpackChunkName: "component---src-pages-who-we-are-about-us-js" */),
  "component---src-pages-who-we-are-introducing-the-holborn-app-js": () => import("./../../../src/pages/who-we-are/introducing-the-holborn-app.js" /* webpackChunkName: "component---src-pages-who-we-are-introducing-the-holborn-app-js" */),
  "component---src-pages-who-we-are-news-js": () => import("./../../../src/pages/who-we-are/news.js" /* webpackChunkName: "component---src-pages-who-we-are-news-js" */),
  "component---src-pages-who-we-are-our-locations-js": () => import("./../../../src/pages/who-we-are/our-locations.js" /* webpackChunkName: "component---src-pages-who-we-are-our-locations-js" */),
  "component---src-pages-who-we-are-our-people-cape-town-js": () => import("./../../../src/pages/who-we-are/our-people/cape-town.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-cape-town-js" */),
  "component---src-pages-who-we-are-our-people-constantia-js": () => import("./../../../src/pages/who-we-are/our-people/constantia.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-constantia-js" */),
  "component---src-pages-who-we-are-our-people-durban-js": () => import("./../../../src/pages/who-we-are/our-people/durban.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-durban-js" */),
  "component---src-pages-who-we-are-our-people-executive-team-js": () => import("./../../../src/pages/who-we-are/our-people/executive-team.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-executive-team-js" */),
  "component---src-pages-who-we-are-our-people-johannesburg-js": () => import("./../../../src/pages/who-we-are/our-people/johannesburg.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-johannesburg-js" */),
  "component---src-pages-who-we-are-our-people-js": () => import("./../../../src/pages/who-we-are/our-people.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-js" */),
  "component---src-pages-who-we-are-our-people-oldlogic-js": () => import("./../../../src/pages/who-we-are/our-peopleOLDLOGIC.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-oldlogic-js" */),
  "component---src-pages-who-we-are-our-people-operational-teams-js": () => import("./../../../src/pages/who-we-are/our-people/operational-teams.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-operational-teams-js" */),
  "component---src-pages-who-we-are-our-people-wealth-managers-js": () => import("./../../../src/pages/who-we-are/our-people/wealth-managers.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-wealth-managers-js" */),
  "component---src-pages-who-we-are-our-people-wider-africa-js": () => import("./../../../src/pages/who-we-are/our-people/wider-africa.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-wider-africa-js" */),
  "component---src-pages-who-we-are-our-people-winelands-js": () => import("./../../../src/pages/who-we-are/our-people/winelands.js" /* webpackChunkName: "component---src-pages-who-we-are-our-people-winelands-js" */),
  "component---src-pages-who-we-are-our-team-js": () => import("./../../../src/pages/who-we-are/our-team.js" /* webpackChunkName: "component---src-pages-who-we-are-our-team-js" */),
  "component---src-pages-who-we-are-your-local-offices-js": () => import("./../../../src/pages/who-we-are/your-local-offices.js" /* webpackChunkName: "component---src-pages-who-we-are-your-local-offices-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-office-template-js": () => import("./../../../src/templates/officeTemplate.js" /* webpackChunkName: "component---src-templates-office-template-js" */),
  "component---src-templates-our-people-single-office-js": () => import("./../../../src/templates/OurPeopleSingleOffice.js" /* webpackChunkName: "component---src-templates-our-people-single-office-js" */),
  "component---src-templates-single-employee-js": () => import("./../../../src/templates/SingleEmployee.js" /* webpackChunkName: "component---src-templates-single-employee-js" */),
  "component---src-templates-single-news-press-js": () => import("./../../../src/templates/singleNewsPress.js" /* webpackChunkName: "component---src-templates-single-news-press-js" */),
  "component---src-templates-single-webinar-js": () => import("./../../../src/templates/singleWebinar.js" /* webpackChunkName: "component---src-templates-single-webinar-js" */)
}

